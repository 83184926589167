
$topMenuBarColor: #010202;

// TitleBar Settings
$titleBarBGColor: $topMenuBarColor;
$titleBarFontColor: rgb(255, 255, 255);

$gradientStartColor: rgba(223, 83, 26, 1);
$gradientEndColor: rgba(151, 15, 183, 1);

// Right Menu
$rightMenuIconsColor: rgb(255, 180, 0);

// background
$background-color: linear-gradient(180deg, #0e2643 0%, #080d15 30%);
$background-color-success: linear-gradient(180deg, #284108 0%, #080d15 30%);
$background-color-error: linear-gradient(180deg, #2d080c 0%, #080d15 30%);
$background-color-black: linear-gradient(180deg, #313336 0%, #000000 30%);
$font-color: #fff;
$font-color-dark: gray;

$warning-color: #FF971D;
$danger-color: #FF0B0B;
$text-color-success: #91DC27;
$radius: .5rem;

// buttons
$button-background: linear-gradient(45deg, #ff971d, #ffb400);
$button-color: white;
$button-danger-background: linear-gradient(45deg, #FF1D1D, #FF4400);
$button-danger-color: white;