@import "../../variables";

.login-wizard {
  min-height: 100vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;

  .form {
    padding-top: 2rem;
    .field {
      width: 100%;
      display: grid;
      grid-template-columns: 2rem 1fr;
      .icon-field {
        vertical-align: bottom;
        height: 100%;
      }
      &.button-field {
        margin-top: 2rem;
        width: 100%;
        display: block;
        button {
          width: 100%;
          background: $button-background;
          color: $button-color;
        }
      }
      &.checkbox-field {
        display: flex;
        align-items: self-start;
        justify-content: flex-start;
        margin-top: 1rem;
        font-size: 12px;
        .MuiCheckbox-root {
          padding: 0;
        }
        label {
          margin-top: .25rem;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
