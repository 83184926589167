@import "../../variables";

.blacklist-wizard {
  background: $background-color-black;
  min-height: 100vh;
  &.success {
    background: $background-color-success;
  }
  &.error {
    background: $background-color-error;
  }
  .blacklist-content {
    padding: 1rem;
    max-width: 750px;
    margin: 0 auto;
  }
}
