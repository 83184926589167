.PageTitle{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        
        .iconSettings{
            fill: #ffb400;
            width: 25px;
            margin-right: 20px;
        }

        h3{
            margin: 0;
            font-weight: 700;
            font-size: 20px;
        }

        h5{
            margin: 0px;
            font-size: 14px;
            font-weight: 500;
            color: #777;
            text-align: left;
        }

    }
}
