@import "../../variables";
.locations {
  padding: 2rem 1rem;
  position: absolute;top: 0;left: 0;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background: $background-color;
  z-index: 1;
  .location-slide {
    margin-bottom: 2rem;
  }
  .details {
    text-align: left;
    .title {
      font-size: .75rem;
    }
  }
  .location {
    text-align: left;
    display: grid;
    //min-height: 2rem;
    background: #272b32;
    margin-bottom: .5rem;
    padding: 1rem;
    border-radius: .5rem;
    .name {
      font-size: 1.5rem;
    }
    .address {
      font-size: .825rem;
      color: $font-color-dark;
      font-weight: normal;
    }

  }
  .details {
    margin-bottom: 1rem;
  }
  .carousel.carousel-slider .control-arrow,
  .carousel.carousel-slider .carousel-status{
    opacity: 0;
  }
}
