.mobile-header-background {
  position: absolute;
  z-index: 0;
  left: 0;
  height: 100%;
}

.grouped-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;

  &.not-authenticated {
    width: auto;

    button {
      margin-left: 3px;
    }

    @media (max-width: 375px) {
      button {
        margin-left: 3px;
      }
    }
  }

  .logInButton {
    width: auto !important;

    &:last-child {
      .MuiIconButton-label {
        display: block;
      }
    }

    .MuiIconButton-label {

      .profileImg {
        max-height: 1.6rem;
        display: flex;
        margin: 0 auto;
      }

      span {
        &.label {
          display: block;
          width: 100%;
          font-weight: 600;
        }
      }
    }

    &:nth-child(1) {
      .MuiIconButton-label {
        padding: .4rem .35rem;
        border-radius: 2rem;
        background: rgba(0, 0, 0, 0.4);
        margin-right: 0;

        h6 {
          font-size: 1rem;
          padding-top: .25rem;
          margin-right: .075rem;

          span {
            color: #969899;
            text-transform: uppercase;
            padding: 0 1.125rem 0 .4rem;
            font-size: .75rem;
            font-weight: normal;

          }
        }
      }

    }

    svg {
      height: 1.75rem;
      width: 1.75rem;
      margin-right: .125rem;

      .cls-2 {
        fill: white;
      }

      .cls-1 {
        fill: #FFB400;
      }
    }
  }
}

.App {
  .headerTopMenu {
    button {
      font-size: .65rem;
      padding: 4px 8px;

      &.register {
        letter-spacing: normal;
        width: auto;
        background: rgb(255, 119, 1);
        background: linear-gradient(79deg, rgba(255, 119, 1, 1) 0%, rgba(255, 194, 0, 1) 100%);
        white-space: nowrap;
      }

      &.connect {
        letter-spacing: normal;
        width: auto;
        background: rgba(14, 133, 65, 1);
        background: linear-gradient(79deg, rgba(14, 133, 65, 1) 0%, rgba(21, 214, 0, 1) 100%);
        white-space: nowrap;
      }

      &.scan {
        padding: 4px 8px;
        width: unset;
        min-width: unset;
        background: #0227B3;
        white-space: nowrap;

        img {
          height: 18px;
        }
      }
    }

    .MuiToolbar-regular {
      @media screen and (min-width: 600px) {
        min-height: 58px;
      }
    }

    .mobile-header-background {
      svg {
        path {
          fill: transparent !important;
        }
      }
    }

    .grouped-buttons {
      .menu-tickets-icon {
        border-radius: 50%;
        background: rgba(0, 0, 0, .41);
        overflow: visible;
        height: 27px;
        width: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: -5px;

        &>span {
          height: 20px;
        }

        img {
          position: relative;
          left: 2px;
          width: 19px;
          height: auto;
        }
      }

      .menu-promo-icon {
        border-radius: 50%;
        background: rgba(0, 0, 0, .41);
        overflow: visible;
        height: 27px;
        width: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: -5px;

        &>span {
          height: 20px;
        }

        img {
          position: relative;
          left: 0px;
          width: 19px;
          height: auto;
        }
      }

      .menu-scan-icon {
        border-radius: 50%;
        background: rgba(0, 0, 0, .41);
        overflow: visible;
        height: 27px;
        width: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        img {
          width: 17px;
          height: auto;
        }
      }

      .logInButton.mr {
        margin-right: 0px;
      }

      .logInButton.scanBut {
        margin: 0;
        padding-right: 0;
      }

      .logInButton span.label {
        text-align: center
      }

      .WalletTopWidget {
        max-width: calc(100% - 20px);
      }
    }

    .winner-hamburger {
      position: relative;
      top: -3px;
    }
  }
}