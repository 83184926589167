@import "../../../variables";

.signature-content {
  padding: 0 2rem;
  text-align: left;
  .signature-wrapper {
    margin: 4rem auto 2rem;
    position: relative;
    width: 300px;
    height: 150px;
    &.active {
      .label {
        opacity: 1;
      }
    }
    .sigCanvas {
      background: rgba(1,1,1,.25);
    }

    &.disabled {
      pointer-events: none;
    }
  }
  label {
    color: $warning-color;
    font-size: .875rem;
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    margin-top: 1rem;
    span {
      &.MuiIconButton-colorSecondary {
        margin-top: -.5rem;

      }
    }
  }
  .label {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: grid;
    text-align: center;
    align-content: center;
    text-transform: uppercase;
    font-size: 1.75rem;
    color: $warning-color;
    border: 3px solid $warning-color;
    border-radius: $radius;
    opacity: 0;
    transition: opacity ease-in-out .25s;
    pointer-events: none;
  }
  .restriction {
    font-size: .75rem;
    color: $font-color-dark;
    .value {
      font-size: 1.25rem;
      color: $font-color;
    }
  }
}