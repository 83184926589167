.druid-scan-document-modal {
  video {
    width: 100vw;
    height: auto;
  }
  .photo-camera {
    path {
      fill: #0A44E4;
    }
    &.top {
      width: 5rem;
      height: auto;
    }
  }
  .webcam-wrapper {
    position: relative;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      &:after,&:before {
        content: " ";
        height: 60%;
        width: 2rem;
        border: 4px solid white;
        position: absolute;
        top: 15%;
      }
      &:after {
        border-left: 0;
        right: 15%;
      }
      &:before {
        border-right: 0;
        left: 15%;
      }
      .actions {
        position: absolute;
        bottom: 5px;
        width: 100%;
        height: 2.5rem;
        background: rgba(1,1,1,.35);
        padding: .35rem;
        box-sizing: border-box;
        button {
          opacity: .9;
          padding: .25rem 1.5rem .25rem .5rem;
          font-size: .7rem;
          svg {
            margin-right: .25rem;
            max-height: .9rem;
          }
          &:hover {
            background: white;
          }
        }
      }
      .title {
        text-align: center;
        position: absolute;
        top: 1rem;
        color: white;
        width: 100%;
        font-weight: bolder;
      }
    }

  }
  button {
    color: #0A44E4;
    border: 2px solid;
    background: white;
    padding: .25rem .5rem;
    &.upload-document {
      font-size: .65rem;
      line-height: 1;
      float: left;
      text-transform: none;
    }
    svg {
      margin-right: .5rem;
      max-height: 1rem;

    }
  }

}