@import "../../../variables";

.exclusion-response {
  min-height: 100vh;
  background: $background-color-error;
  .result {
    .icon {
      min-height: 5rem;
      padding: 3rem;
    }
    .details {
      p {
        font-weight: normal;
      }
      h2 {
        text-transform: uppercase;
      }
    }
  }

  &.ok {
    .details {
      color: $font-color;
    }
  }
  &.timeout {

    .details {
      padding: 2rem;
      color: whitesmoke;
      text-align: left;
      p {
        font-size: 1.5rem;
      }
    }
  }

  .exclusion-expire {
    margin-top: 10rem;
    font-size: .75rem;
    padding: 0 2rem;
    .time {
      font-size: 1.5rem;
    }
    button {
      width: calc(100% - 4rem);
      color: whitesmoke;
      margin-top: 1rem;
      font-size: 1.25rem;
      padding: 1rem;
      text-transform: uppercase;
    }
  }

  .safety-badge {
    display: none;
  }
}