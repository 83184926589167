@import "../../variables";

.actions-content {
  .buttons {
    padding: 1rem 1rem 4rem 1rem;
  }
}

.bottom-actions {
  position: absolute;
  bottom: 2rem;
  padding: 0 1rem;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  left: 0;
  a {
    text-align: left;
    color: $font-color-dark;
    &:last-child {
      text-align: right;
    }
  }
}