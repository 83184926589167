@import "../variables";
@import "../fonts.scss";

.App {
  text-align: center;
  background: $background-color;
  color: $font-color;
  * {
    font-family: "OpenSans", "Open Sans";
    font-weight: bold;
  }
  .MuiFormLabel-root {
    color: $font-color;
  }
  .MuiFormHelperText-root {
    color: $font-color;
    opacity: .8;
  }
  .MuiInputBase-root {
    color: $font-color;
  }
  .MuiButton-contained.Mui-disabled {
    color: $font-color;
    opacity: .25;
    svg {
      opacity: .25;
    }
  }
  a {
    color: $font-color;
  }
  svg {
    fill: $font-color;
    opacity: .8;
  }
  .headerTopMenu {
    position: fixed;
    width: 100vw;
    top: -100px;
  }

  .AppTopMenuBar {
    background-color: $topMenuBarColor;
    position: relative;
    border-bottom: 1px solid #ffb400;
    max-height: 58px;
    .MuiToolbar-root {
      justify-content: space-between;
    }
  }
  .App-body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: $font-color;
  }

  .animatedPageContent {
    position: relative;
    min-height: calc(100vh - 3rem);
    display: block;
    width: 100vw;
  }
}
.logo-hero-square {
  width: 100%;
  padding-bottom: 3rem;
  max-height: 35vh;
  position: relative;
  box-sizing: border-box;
  height: 40vh;
  img {
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }
}

.logo-hero {
  width: 100%;
  max-width: 450px;
  padding: 5rem 1rem 15rem 1rem;
  box-sizing: border-box;
  img {

  }
}
.login-wizard {
  .logo-hero {
    padding-bottom: 5rem;
  }
}
.actions {
  position: absolute;
  bottom: 1rem;
  right: 1rem;

}

a {
  &.actions {
      color: $font-color-dark;
  }
}
.buttons {
  padding: 2rem;
}
.button {
  width: 100%;
  padding: 1.5rem 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  background: $button-background;
  color: $button-color;
  border-radius: 5px;
  display: inline-block;
  box-sizing: border-box;
  outline: none;
  border: none;
  margin-bottom: 1rem;
  text-decoration: none;
  &.danger {
    background: $button-danger-background;
    color: $button-danger-color;
  }
  &:disabled,&.disabled {
    filter: grayscale(1);
    pointer-events: none;
  }
}
.error-band {
  background: rgba(red,.1);
  width: 100%;
  min-height: 2rem;
  padding: 1rem;
  box-sizing: border-box;
  color: red;
  text-transform: uppercase;
}
.App .text-error {
  fill: $danger-color;
  color: $danger-color;
}

.uppercase {
  text-transform: uppercase;
}
.container {
  max-width: 450px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}