@import "../../variables";
.recent-players {
  padding: 0 1rem 2rem 1rem;
  .player-slide {
    margin-bottom: 2rem;

  }
  .details {
    text-align: left;

    .title {
      font-size: .75rem;
    }
  }
  .player {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 5rem;
    min-height: 5rem;
    background: #272b32;
    margin-bottom: .5rem;
    padding: 1rem;
    border-radius: .5rem;
    .name {
      font-size: 1.5rem;
    }
    .cnp {
      color: $font-color-dark;
      font-size: .75rem;
    }
    .info {
      display: grid;
      .years {
        color: $font-color-dark;
        font-weight: normal;
        font-size: .75rem;
      }
    }
    .creation-date {
      font-weight: normal;
    }
  }
  .carousel.carousel-slider .control-arrow,
  .carousel.carousel-slider .carousel-status{
    opacity: 0;
  }
}
