@import '../../variables';

.info-message-druid {
  color: #009245;
  background: #E3FFF0;
  border-radius: .25rem;
  font-size: .85rem;
  padding: .85rem;
  width: calc(100% - 1rem);
  box-sizing: border-box;
  margin-bottom: 1rem;

}
.druid-scanner-wrapper {
  width: calc(100% );
  margin-bottom: 5rem;
  box-sizing: border-box;
  position: relative;
  &.success {
    background: $background-color-success;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    height: calc(100vh - 2rem);
    margin-top: calc(-50vh - 56px);
  }
  &.error {
    background: $background-color-error;
  }
  .druid-scanner {
    border:  3px solid rgba(255,255,255,.25);
    border-radius: 9px;
    position: relative;
    padding: .25rem;
    background: $button-background;

    .labels {
      position: absolute;
      width: 100%;
      top: -.75rem;
      font-size: .75rem;
      left: -0.5rem;
      &> div {
        display: inline-block;
        margin-right: .5rem;
        padding: .2rem .7rem;
        border-radius: .35rem;
        box-shadow: 0 3px 5px #0000003D;
        color: $button-color;
        &.danger {
          background: #C11823;
          color: white;
        }
        &.warning {
          background: #EB6E00;
          color: white;
        }
      }
    }
    .content {
      display: grid;
      grid-template-columns: 1fr 80px;

      .text {
        color: $button-color;
        padding: 1.25rem 1rem .5rem 1.25rem;
        text-align: center;
        .title {
          font-size: 1.25rem;
          text-align: left;
        }
        .description {
          font-size: .9rem;
          font-weight: normal;
        }
      }
      .upload-image-wrapper {
        height: 72px;
        padding: 2px 4px 4px 4px;
        .upload-image {
          background: linear-gradient(54deg,#6B7892,#3D4963);
          border-radius: 6px;
          width: 72px;
          height: 72px;
          display: flex;
          align-items: center;
        //  margin: .25rem;
        //  height: 72px;
        //  width: 72px;
          border: 1px solid #7888A8;
          img {
            margin: 0 auto;
          }
          div {
            margin: 0 auto;
            color: white;
          }
        }
      }
    }
  }
  .error {
    font-size: .75rem;
    color: #D80000;
  }
  .success {
    font-size: .75rem;
    color: #009245;
  }
  .safety-badge {
    position: absolute;
    right: 1rem;
    padding: 2px;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 30px 1fr;
    border-radius: 14px;
    font-size: 12px;
    width: 140px;
    background: #009245;
    color: white;
    text-transform: uppercase;
    align-content: center;
    align-items: center;

    .icon {
      background: white;
      display: grid;
      align-items: center;
      align-content: center;
      margin: -5px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      img {
        margin-top: 1px;
        margin-left: 5px;
      }
    }
  }
}
.druid-modal {
  .MuiPaper-rounded {
    border-radius: 1rem;
  }

  .dialog-content {
    padding: 4rem 2rem !important;
    text-align: center;
    color: #285C98;
    .IconSVG {
      margin: 0 auto;
      width: 5rem;
      height: 5rem;
    }
    .dialog-title {
      font-size: 1.25rem;
      font-weight: 600 !important;
      margin-top: -1.4rem;
      letter-spacing: -.3px;
    }
    .druid-modal-message {
      padding: 3.5rem 0 7rem 0;
      font-size: .85rem;
      color: black;
      .ProgressBar {
        height: 3px !important;
        margin: 1.15rem auto 0 auto;
        width: 60%;
        > div {
          height: 3px !important;
        }
      }
    }
    .error {
      font-size: .75rem;
      color: #D80000;
      & + div .MuiLinearProgress-bar{
        background: #D80000;
      }
    }
    .success {
      font-size: .75rem;
      color: #009245;
      & + div .MuiLinearProgress-bar{
        background: #009245;
      }
    }
  }

}