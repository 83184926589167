@import "../../../variables";

.scan-response {
  min-height: calc(100vh - 4rem);
  background: $background-color-error;
  .result {
    .icon {
      min-height: 4rem;
      padding-top: 3rem;
    }

  }
  .details {
    color: $font-color-dark;
    text-align: center;
    margin-top: -2rem;
  }
  .safety-badge {
    display: none;
  }
  &.success {
    background: $background-color-success;
    .result {
      .name {
        font-size: 1.5rem;
        margin: 1rem 2rem 5rem;
      }
      .details {
        color: $text-color-success;
        p {
          font-weight: normal;
          margin-bottom: 0;
        }
        h2 {
          margin-top: .5rem;
          font-size: 1.75rem;
          text-transform: uppercase;
        }
      }
    }
    .buttons {
      margin-top: 15vh;
    }
  }
  &.reading-error,
  &.document-invalid,
  &.child-document,
  &.locked{

    h1 {
      color: $danger-color;
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        left: 50%;
        width: 50px;
        margin-left: -35px;
        height: 3px;
        bottom: -0.5rem;
        background: $danger-color;
      }
    }
    .details {
    }
  }
  &.locked {
    background: linear-gradient(#CF0000,#080D15,#080D15);
    h1 {
      margin-bottom: 2rem;
      color: whitesmoke;
      &:after {
        display: none;
      }
    }
    .details {
      padding: 1rem;
      color: $danger-color;
      p {
        font-weight: normal;
        margin-bottom: 0;
      }
      h2 {
        margin-top: .5rem;
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }
    .result {
      margin: 2rem;
      background: linear-gradient(#1E0808, #1E0808, #080D15);
      border-radius: 27px;
    }
    .exclusion-expire {
      padding-left: 2rem;
      padding-right: 2rem;
      margin-top: 0;
    }
  }
  .exclusion-expire {
    margin-top: 10rem;
    font-size: .75rem;

    .time {
      font-size: 1.5rem;
    }
    button {
      width: calc(100% - 4rem);
      color: whitesmoke;
      margin-top: 1rem;
      font-size: 1.25rem;
      padding: 1rem;
      text-transform: uppercase;
    }
  }

}